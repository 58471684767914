import React from 'react';
import { useTranslation } from 'react-i18next';
import SortableColumn, { Sortable } from '../../../../../components/listing/sortable-column/sortable-column';
import { CreditEstimateToList } from '../../../../../model/estimate';
import { Comparable, useSimulationInfoContext } from '../simulation-info/simulation-info-context';

export const InvoiceHeader = () => {
    const { t } = useTranslation();
    const { sortedProperty, sortOrder, handleSort } = useSimulationInfoContext();

    const mapSortable = (_property: keyof CreditEstimateToList, mapper: (credit: CreditEstimateToList) => Comparable): Sortable => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: false,
            onSort: () => handleSort(_property, sortOrder, mapper)
        };
    };

    return (
        // provisional styles
        <div className="container__invoices--header-second">
            <SortableColumn
                label={t('anticipation-simulation.credits.invoice').toUpperCase()}
                sortable={mapSortable('nfNumber', estimate => estimate.nfNumber)}
            />
            <SortableColumn
                label={t('anticipation-simulation.credits.expireDate').toUpperCase()}
                sortable={mapSortable('expireDate', estimate => estimate.expireDate)}
            />
            <SortableColumn
                label={t('anticipation-simulation.credits.originalValue').toUpperCase()}
                sortable={mapSortable('originalValue', estimate => estimate.originalValue)}
            />
            <SortableColumn
                label={t('anticipation-simulation.credits.discount').toUpperCase()}
                sortable={mapSortable('discount', estimate => estimate.discount)}
            />
            <SortableColumn
                label={t('anticipation-simulation.credits.anticipation').toUpperCase()}
                sortable={mapSortable('anticipation', estimate => estimate.anticipation)}
            />
           
        </div>
    );
};

export default InvoiceHeader;
