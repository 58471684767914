import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Idenfitication from '../../components/format/identification/Identification';
import Loading from '../../components/loading/loading';
import { GIRO_API_CONSULT, GIRO_PHONE_CONTACT } from '../../config/constants';
import { Authority } from '../../model/enums/authorities';
import { ErrorConstants, ErrorType } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../model/error';
import { hasAcceptedTermsResetState } from '../../reducer/account/terms-of-use/actions';
import { userAccountRequest, userAccountResetState } from '../../reducer/account/user-account/actions';
import { customizeErrorHandling } from '../../reducer/application/error/actions';
import { logoutRequest } from '../../reducer/authentication/actions';
import { defaultCompanyResetState, getDefaultCompanyRequest } from '../../reducer/company/default/actions';
import { useCompanyDefaultState, useRootDispatch, useUserAccountState } from '../../reducer/hooks';
import authUtil from '../../services/api/authUtil';
import { useIsCompanySelectionRequired } from '../../shared/hooks/company-selection-required';
import { useDoesHeaderProviderHasError } from '../../shared/hooks/header-provider-has-error';
import { useIsHeaderProviderLoading } from '../../shared/hooks/header-provider-is-loading';
import { useHandleUserNotActivated } from '../../shared/hooks/user-not-activated';
import notificationStorage from '../../shared/notification/notification-storage';
import ModalCompany from '../modal-company/modal-company';
import './header-provider.scss';

const useAccount = () => {
    const dispatch = useRootDispatch();
    const state = useUserAccountState();

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.NOOP) {
            dispatch(userAccountRequest());
        }
    }, [state.status, dispatch]);
};

const useCompany = () => {
    const dispatch = useRootDispatch();
    const accountState = useUserAccountState();
    const companyState = useCompanyDefaultState();

    const isProvider = useHasAuthority(Authority.ROLE_PROVIDER);

    React.useEffect(() => {
        if (
            isProvider &&
            accountState.account?.activated &&
            accountState.status === HttpRequestStatus.SUCCESS &&
            companyState.status === HttpRequestStatus.NOOP
        ) {
            dispatch(getDefaultCompanyRequest());
        }
    }, [isProvider, accountState.status, accountState.account, companyState.status, dispatch]);
};

const useHasAuthority = (authority: Authority): boolean | undefined => {
    const accountState = useUserAccountState();

    return accountState.status === HttpRequestStatus.SUCCESS
        ? !!accountState.account?.authorities?.find(it => it === authority)
        : undefined;
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();
    const history = useHistory();

    React.useEffect(() => {
        const handleLogoff = () => {
            dispatch(logoutRequest());
            authUtil.removeToken();
            history.push('/');
            dispatch(userAccountResetState());
            dispatch(hasAcceptedTermsResetState());
            dispatch(defaultCompanyResetState());
            notificationStorage.reset();
        };

        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.person.notTeamMember': {
                type: ErrorType.BLOCKING,
                message: {
                    key: 'header.header-provider.error.not-team-member',
                    options: { contact_number: GIRO_PHONE_CONTACT }
                },
                action: {
                    label: 'header.header-provider.error.exit',
                    handler: handleLogoff
                }
            }
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch, history]);
};

export const HeaderProvider = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);

    useErrorConfiguration();
    const isLoading = useIsHeaderProviderLoading();
    const hasError = useDoesHeaderProviderHasError();
    const requireCompanySelection = useIsCompanySelectionRequired();

    useAccount();
    const isProvider = useHasAuthority(Authority.ROLE_PROVIDER);
    const isProviderConsult = useHasAuthority(Authority.ROLE_PROVIDER_CONSULT);
    useCompany();
    const companyState = useCompanyDefaultState();
    useHandleUserNotActivated();

    React.useEffect(() => {
        if (!isLoading && !hasError && isProvider === false) {
            history.push('/giro-api');
        }
    }, [isLoading, hasError, isProvider, history]);

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleChangeCompany = () => {
        setShowModal(false);
        history.push('/dashboard');
    };

    return (
        <div className="container__header-provider">
            <Container>
                <Row>
                    <Col style={{ height: '70px' }} md={{ size: 2 }}>
                        <div className="header-provider">
                            <div className="header-provider--logo" />
                        </div>
                    </Col>
                    <Col md={{ size: 5 }}>
                        <div className="header-provider--menu-itens">
                            <Link className="header-provider--link" to="/dashboard">
                                {t('header.header-provider.first-menu')}
                            </Link>
                            {companyState.company?.canAnticipate && (
                                <Link className="header-provider--link" to="/program-selection">
                                    {t('header.header-provider.second-menu')}
                                </Link>
                            )}
                        </div>
                    </Col>
                    <Col md={{ size: 3 }}>
                        <div className="header-provider--company">
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    <div className="header-provider--company-text">{companyState.company?.name}</div>
                                    <div className="header-provider--company-text">
                                        <Idenfitication type={companyState.company?.type} value={companyState.company?.identification} />
                                    </div>
                                    <button className="company--buttom" onClick={() => setShowModal(!showModal)}>
                                        {t('header.buttom').toUpperCase()}
                                    </button>
                                </>
                            )}
                        </div>
                    </Col>
                    <Col md={{ size: 2 }}>
                        <div className="header-provider--profile">
                            <Link className="header-provider--link" to="/profile">
                                {t('header.header-provider.profile')}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalCompany
                open={showModal}
                required={requireCompanySelection}
                closeModal={handleModalClose}
                onAccess={handleChangeCompany}
            />
        </div>
    );
};

export default HeaderProvider;
