/**
 *
 * Custom configuration for Finvex.Tech organization
 *
 *            **WARNING**
 * Make sure to make changes in custom folder
 * Changes made in source will be ignored
 * @see /custom/giro/config.js
 *
 */

const localization = {
    i18n: {
        /**
         * Should reference atribute defined in i18n resources and import json file
         * @example import pt_BR from '../i18n/custom_organization/pt-br.json';
         * @see /src/config/i18n.ts
         *
         * Should use hifen if need, avoid underscore
         * @example pt-BR instead of pt_BR
         * @link https://github.com/i18next/i18next/issues/1061#issuecomment-395528467
         */
        default_language: 'en-za'
    },
    moment: {
        /**
         * Should reference valid moment's locale directory name and import locale
         * @example
         * import 'moment/locale/pt-br';
         * @see /src/shared/util/format-utils.ts
         * @link https://www.ge.com/digital/documentation/predix-services/c_custom_locale_support.html#concept_uly_5xv_l1b__section_w2b_qwy_m1b
         */
        locale: 'en-za'
    },
    Intl: {
        /**
         * Should reference valid IntL`s locale key
         * @see /src/shared/util/format-utils.ts
         */
        locale: 'en-za',
        /**
         * Should reference valid IntL`s currency key
         * @see /src/shared/util/format-utils.ts
         */
        currency: 'ZAR'
    }
};

const contact = {
    phone: undefined
};

const format = {
    phone: {
        regex: /^[0-9]{9}$/
    },
    identification: {
        individual: {
            regex: undefined,
            mask: undefined
        },
        corporation: {
            regex: /[0-9]{4}\/[0-9]{6,7}\/(06|07|08|09|10|12|21|22|23|24|25|26|30|31)/,
            mask: '$1/$2/$3'
        }
    }
};

const configuration = {
    localization,
    contact,
    format
};

export default configuration;
