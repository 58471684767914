import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Authentication, Token } from '../../model/authentication';
import { api } from './api';

export const authenticate = () => {
    const login = (authentication: Authentication): Promise<AxiosResponse<Token>> => {
        const auths = [
            `username=${encodeURIComponent(authentication.username ?? '')}`,
            `password=${encodeURIComponent(authentication.password ?? '')}`,
            'grant_type=password',
            'scope=read write',
            'client_secret=my-secret-token-to-change-in-production',
            'client_id=giro_apiapp',
            'device=web'
        ];
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-OrganizationID': 'finvex'
            }
        };
        return api.post<Token>('api/oauth/token', auths.join('&'), config);
    };

    const logout = () => {
        return api.post<void>('api/oauth/logout');
    };

    return {
        login,
        logout
    };
};

export default authenticate();
