import React from 'react';
import { useEstimateRequestState, useListProgramsState } from '../../../../../reducer/hooks';
import './anticipation-term-session.scss';

import { useTranslation } from 'react-i18next';
export const PromiseTermSession = () => {
    const { t } = useTranslation();
    const { estimate } = useEstimateRequestState();

    const { programs } = useListProgramsState();

    const program = programs?.find(it => it.providerId === estimate?.programProviderId);

    if (program?.sessionPromiseId != null) {
        return <></>;
    }

    return (
        <div className="body__confirmation--title-start">
            <span className="body__confirmation--title-start-one">
                {/* {t('anticipation-simulation.anticipation-request-confirmation.promise')} */}
                As your first anticipation, you will receive the Financial Agent terms in your email to sign and complete the request.
            </span>
        </div>
    );
};

export default PromiseTermSession;
